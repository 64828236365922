import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getOneSipServer } from '../../api/SipServerApi';
import { URLS } from '../../utils/urls.util';
import { SipServerForm } from '../SipServerForm/SipServerForm';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';

export const SingleSipServer = ({ id, permission }) => {
  const [isCreate, setIsCreate] = useState(false);
  const [sipServer, setSipServer] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== 'create') {
      getOneSipServer(id, [])
        .then((sipServer) => setSipServer(sipServer))
        .catch((error) =>
          dispatch(setAlert([{ type: 'error', text: error.message }])),
        );
    }
  }, []);

  useState(() => {
    if (id === 'create') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [id]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="text.primary"
      to={URLS.SIP_SERVERS}
    >
      SIP-серверы
    </Link>,
    <Typography key="3" color="text.primary">
      {isCreate ? 'Добавить SIP-сервер' : sipServer.name}
    </Typography>,
  ];

  return (
    (id || isCreate) && (
      <FullPageLayout
        seoTitle={
          'SIP-серверы - ' + (sipServer?.name || 'Добавить SIP-сервер')
        }
        pageTitle={isCreate ? 'Добавить SIP-сервер' : sipServer?.name}
        obj={sipServer}
        showStatus={false}
        breadcrumbs={breadcrumbs}
        isCreated={true}
      >
        <SipServerForm
          prevPage={URLS.SIP_SERVERS}
          isCreate={isCreate}
          sipServer={sipServer}
          permission={permission}
        />
      </FullPageLayout>
    )
  );
};
