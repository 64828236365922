import React from 'react';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';

export const SipServerFormActionButtons = ({
  sipServer,
  formDisabled,
  handleSaveNewSipServer,
  handleUpdateSipServer,
  handleRemoveSipServer,
  handleChancel,
  showDeleteButton = true,
  permission,
  loading,
}) => {
  return (
    <>
      <FooterButtonBar
        showDeleteButton={
          permission.delete && sipServer?.id && showDeleteButton
        }
        disabledDeleteButton={!permission.delete}
        deleteButtonHandler={() => handleRemoveSipServer()}
        cancelButtonHandler={() => handleChancel()}
        showCancelButton={true}
        showUpdateButton={permission.edit && sipServer?.id}
        updateButtonHandler={() => handleUpdateSipServer()}
        disabledUpdateButton={!permission.edit || formDisabled}
        disabledCreateButton={!permission.create || formDisabled}
        createButtonHandler={() => handleSaveNewSipServer()}
        showCreateButton={permission.create && !sipServer?.id}
        loading={loading}
      />
    </>
  );
};
