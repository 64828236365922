import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { SipServerTable } from '../components/SipServerTable/SipServerTable';
import { pagesIds } from '../utils/pages';
import { URLS } from '../utils/urls.util';

export const SipServerPage = () => {
  const [permission, setPermission] = useState({});
  const navigate = useNavigate();

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Typography key="2" color="text.primary">
      SIP-серверы
    </Typography>,
  ];
  return (
    <MainContainer
      pageId={pagesIds.sipServers}
      setPermission={setPermission}
      title="SIP-серверы"
      titleAs="h4"
      titleStyles={{ marginTop: '8px' }}
      breadcrumbs={breadcrumbs}
      actionButton={
        permission?.create
          ? {
              text: 'Добавить SIP-сервер',
              action: () => navigate(URLS.SIP_SERVERS + '/create'),
            }
          : null
      }
    >
      <SipServerTable permission={permission} />
    </MainContainer>
  );
};
