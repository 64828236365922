import axios from 'axios';
import { ENV } from '../utils/env';
import AuthService from './KeycloakApi';

export const getAllDevices = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/get-all`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const uploadDeviceFile = async (id, versionId, revisionId, file) => {
  try {
    const token = AuthService.getToken();
    const formdata = new FormData();
    formdata.append('file', file);

    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/${id}/${versionId}/${revisionId}/upload`,
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'multipart/form-data',
      },
      data: formdata,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editDeviceVersionRevision = async (id, data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/${id}/edit-version-revision`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const getOneDevice = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/get-one/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getOneDeviceVersionRevision = async (id, versionRevisionId) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/get-version-revision/${id}/${versionRevisionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getOneDeviceRevisionMap = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/get-one/${id}/revisions`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getVersionRevisionUsage = async (versionRevisionId) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/get-version-revision-usage/${versionRevisionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);

    return result.data;
  } catch (error) {
    throw error;
  }
};


export const getDeviceCategories = async (relations) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/device-category/get-all`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        relations,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const deleteDevice = async (id) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/remove/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateDevice = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'patch',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/update`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editDevice = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/edit`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAllDeviceParametersCategories = async (
  limit,
  offset,
  relations,
) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/parameters-categories`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit: limit,
        offset: offset,
        relations,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getOldVersionRevision = async ({
  deviceId,
  revisionId,
  versionId,
}) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/old/${deviceId}/${revisionId}/${versionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getVersionRevisionFile = async ({
  deviceId,
  versionRevisionId,
}) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/get-file/${deviceId}/${versionRevisionId}`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/zip',
      },
    };

    const result = await axios(config);

    return result.data;
  } catch (error) {
    throw error;
  }
};

export const addNewDeviceParametersCategory = async (category) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/parameters-categories/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: category,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateDeviceParametersCategory = async (category) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'put',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/parameters-categories/update`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: category,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDeviceParametersCategory = async (category) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/parameters-categories/remove`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: category,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createDeviceRevision = async (id, dto) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/create-revision/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: dto,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const createDeviceVersion = async (id, dto) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/create-version/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: dto,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeDeviceVersion = async (id, versionId) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/delete-version/${id}/${versionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const removeDeviceVersionRevision = async (id, versionRevisionId) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/delete-version-revision/${id}/${versionRevisionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeDeviceRevision = async (id, revisionId) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/device/delete-revision/${id}/${revisionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
