import axios from 'axios';
import { ENV } from '../utils/env';
import AuthService from './KeycloakApi';

export const getAllSipServers = async (limit = 16, offset = 0) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/sip-server/get-all`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit: limit,
        offset: offset,
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const checkApiUrl = async (apiUrl) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/sip-server/check-api-url`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        apiUrl,
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const checkDomain = async (domain) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/sip-server/check-domain`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        domain,
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const checkUrl = async (url) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/sip-server/check-url`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        url,
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const saveNewSipServer = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'post',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/sip-server/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateSipServer = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'patch',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/sip-server/update`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getOneSipServer = async (id, relations = []) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/sip-server/get/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        relations,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSipServer = async (data) => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'delete',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/sip-server/remove`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
