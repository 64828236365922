import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createDeviceRevision,
  createDeviceVersion,
  getOneDevice,
  getOneDeviceRevisionMap,
  removeDeviceRevision,
  removeDeviceVersion,
  removeDeviceVersionRevision,
} from '../../api/DeviceApi';
import { useApi } from '../../hooks/useApi';
import { setAlert } from '../../store/alert.store';
import { Link, useNavigate } from 'react-router-dom';
import styles from './SingleDeviceRevisionsTable.module.scss';
import classNames from 'classnames';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { URLS } from '../../utils/urls.util';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import { FormValidator } from '../FormValidator/FormValidator';
import { DeviceVersionAndRevisionAddForm } from '../../utils/formValidatorConfigs/config';
import { getVersionRevisionUsage } from '../../api/DeviceApi';

export const SingleDeviceRevisionsTable = ({ id, permission }) => {
  const getOneDeviceApi = useApi(getOneDevice);
  const createDeviceRevisionApi = useApi(createDeviceRevision);
  const createDeviceVersionApi = useApi(createDeviceVersion);
  const removeDeviceVersionApi = useApi(removeDeviceVersion);
  const removeDeviceRevisionApi = useApi(removeDeviceRevision);
  const getOneDeviceRevisionMapApi = useApi(getOneDeviceRevisionMap);
  const getVersionRevisionUsageApi = useApi(getVersionRevisionUsage);
  const [isCreate, setIsCreate] = useState(false);
  const [device, setDevice] = useState({});
  const [revisions, setRevisions] = useState({});
  const [selectRevision, setSelectRevision] = useState(null);
  const [selectVersion, setSelectVersion] = useState(null);
  const [selectVersionRevision, setSelectVersionRevision] = useState(null);
  const [contextMenuAnchor, setContextMenuAnchor] = useState(false);
  const [openRevisonApprove, setOpenRevisionApprove] = useState({});
  const [openVersionApprove, setOpenVersionApprove] = useState({});
  const [openVersionRevisionApprove, setOpenVersionRevisionApprove] = useState(
    {},
  );
  const [openAdd, setOpenAdd] = useState({ open: false });
  const [newRevision, setNewRevision] = useState('');
  const [newVersion, setNewVersion] = useState('');
  const [validateErrors, setValidateErrors] = useState([]);
  const [changedData, setChangedData] = useState({
    version: false,
    revision: false,
  });
  const [versionValidationError, setVersionValidationError] = useState(null);

  const removeDeviceVersionRevisionApi = useApi(removeDeviceVersionRevision);

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  const config = useMemo(() => DeviceVersionAndRevisionAddForm(), []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id !== 'create') {
      getOneDeviceApi
        .sendRequest(id, [
          'parametersCategories',
          'parametersCategories.parameters',
          'deviceCategory',
        ])
        .then((device) => setDevice(device))
        .catch((error) =>
          dispatch(
            setAlert({
              status: 500,
              text: error.message,
            }),
          ),
        );

      getOneDeviceRevisionMapApi
        .sendRequest(id)
        .then((result) => setRevisions(result));
    }
  }, []);

  useEffect(() => {
    if (id == 'create') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [id]);
  const navigate = useNavigate();
  const removeRevisionHandler = async (revisionId) => {
    const selectRevisionCandidate = [].concat(
      revisions.rows?.map((r) =>
        r.cells?.find((cell) => cell.revisionId == revisionId),
      ),
    );

    const isExist = selectRevisionCandidate.some(
      (cell) => cell.versionRevisionId,
    );

    if (isExist) {
      setOpenRevisionApprove({
        openModal: true,
        title:
          'Вы не можете удалить ревизию ПО, так как есть заполненные ячейки.',
        actionButtonText: 'Понятно',
        showCloseButton: false,
        handleSuccess: () =>
          setOpenRevisionApprove({
            ...openRevisonApprove,
            openModal: false,
          }),
        handleClose: () =>
          setOpenRevisionApprove({
            ...openRevisonApprove,
            openModal: false,
          }),
      });
    } else {
      setOpenRevisionApprove({
        openModal: true,
        title: 'Вы уверены что хотите удалить ревизию?',
        actionButtonText: 'Удалить',
        showCloseButton: true,
        handleSuccess: async () => {
          try {
            await removeDeviceRevisionApi.sendRequest(device.id, revisionId);
            if (revisions?.headers?.length == 1) {
              const clearVersion = revisions?.rows?.map((r) => r.versionId);
              for (const cv of clearVersion) {
                await removeDeviceVersionApi.sendRequest(device.id, cv);
                setRevisions({});
              }
            }
            const data = await getOneDeviceRevisionMapApi.sendRequest(id);
            setRevisions(data);
            setOpenRevisionApprove({
              ...openRevisonApprove,
              openModal: false,
            });
            dispatch(
              setAlert({
                status: 200,
                text: 'Матрица ревизий и версий обновлена',
              }),
            );
          } catch (error) {}
        },
        handleClose: () =>
          setOpenRevisionApprove({
            ...openRevisonApprove,
            openModal: false,
          }),
      });
    }
  };

  const removeVersionHandler = async (versionId) => {
    let selectedrowCandidate = revisions.rows?.find(
      (row) => row.versionId == versionId,
    );

    const isExist = selectedrowCandidate?.cells?.some((cell) => {
      return cell.versionRevisionId;
    });
    if (isExist) {
      setOpenVersionApprove({
        openModal: true,
        title:
          'Вы не можете удалить версию ПО, так как есть заполненные ячейки.',
        actionButtonText: 'Понятно',
        showCloseButton: false,
        handleSuccess: () => {
          setOpenVersionApprove({
            ...openVersionApprove,
            openModal: false,
          });

          setSelectVersion(null);
        },
        handleClose: () => {
          setOpenVersionApprove({
            ...openVersionApprove,
            openModal: false,
          });
          setSelectVersion(null);
        },
      });
    } else {
      setOpenVersionApprove({
        openModal: true,
        title: 'Вы действительно хотите удалить версию?',
        actionButtonText: 'Удалить',
        showCloseButton: true,
        handleSuccess: async () => {
          try {
            await removeDeviceVersionApi.sendRequest(device.id, versionId);
            if (revisions?.rows?.length == 1) {
              const clearRevisions = revisions?.headers?.map(
                (h) => h.revisionId,
              );
              for (const cr of clearRevisions) {
                await removeDeviceRevisionApi.sendRequest(device.id, cr);
                setRevisions({});
              }
            }
            const data = await getOneDeviceRevisionMapApi.sendRequest(id);
            setRevisions(data);
            setOpenVersionApprove({
              ...openVersionApprove,
              openModal: false,
            });
            dispatch(
              setAlert({
                status: 200,
                text: 'Матрица ревизий и версий обновлена',
              }),
            );
          } catch (error) {}
        },
        handleClose: () =>
          setOpenVersionApprove({
            ...openVersionApprove,
            openModal: false,
          }),
      });
    }
  };
  const removeVersionRevisionHandler = async (versionRevisionId) => {
    const checker = revisions.rows
      .find((row) =>
        row.cells.find(
          (cell) =>
            cell.isExist && cell.versionRevisionId === versionRevisionId,
        ),
      )
      ?.cells?.find((c) => c.versionRevisionId === versionRevisionId);

    if (checker?.isExist) {
      const maxCount = 5;
      const usage = await getVersionRevisionUsageApi.sendRequest(checker.versionRevisionId);
      let orgList;
      if (usage.length < maxCount) {
        orgList = usage.map((item) => item.companyName).join(', ');
      } else {
        const remain = usage.length - maxCount;
        orgList = usage.slice(0, maxCount).map((item) => item.companyName).join(', ') + ` и еще ${remain}`;
      }

      setOpenVersionRevisionApprove({
        openModal: true,
        title:
          `Вы не можете удалить данную комбинацию версии и ревизии, когда она используется в АРМ организациями: ${orgList}`,
        actionButtonText: 'Понятно',
        showCloseButton: false,
        handleSuccess: () =>
          setOpenVersionRevisionApprove({
            ...openVersionRevisionApprove,
            openModal: false,
          }),
        handleClose: () =>
          setOpenVersionRevisionApprove({
            ...openVersionRevisionApprove,
            openModal: false,
          }),
      });
    } else {
      setOpenVersionRevisionApprove({
        openModal: true,
        title: 'Вы уверены, что хотите удалить комбинацию версии ревизии?',
        actionButtonText: 'Удалить',
        showCloseButton: true,
        handleSuccess: async () => {
          try {
            await removeDeviceVersionRevisionApi.sendRequest(
              device.id,
              versionRevisionId,
            );

            const data = await getOneDeviceRevisionMapApi.sendRequest(id);
            setRevisions(data);
            setOpenVersionRevisionApprove({
              ...openVersionRevisionApprove,
              openModal: false,
            });
            dispatch(
              setAlert({
                status: 200,
                text: 'Матрица ревизий и версий обновлена',
              }),
            );
          } catch (error) {}
        },
        handleClose: () =>
          setOpenVersionRevisionApprove({
            ...openVersionRevisionApprove,
            openModal: false,
          }),
      });
    }
  };

  useEffect(() => {
    const regex = new RegExp(/^((\d{1,3})\.)((\d{1,3})\.)(\*|(\d{1,3}))$/gm);

    if (newVersion) {
      if (!regex.test(newVersion)) {
        setVersionValidationError(
          'Версия должна соответствовать формату х.х.х, где х - число',
        );
      } else {
        const splitVersion = newVersion.split('.');
        if (splitVersion.some((v) => v.length > 1 && v.charAt(0) == '0')) {
          setVersionValidationError(
            'Число до точки больше одного символа. Ставить ноль в начало числа нельзя.',
          );
        } else {
          setVersionValidationError(false);
        }
      }
    }
  }, [newVersion]);

  const createRevisionHandler = async (data) => {
    try {
      await createDeviceRevisionApi.sendRequest(device.id, data);
    } catch (error) {
      throw error;
    }
  };

  const createVersionHandler = async (data) => {
    try {
      await createDeviceVersionApi.sendRequest(device.id, data);
    } catch (error) {
      throw error;
    }
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.DEVICES}>
      Список типов оборудования
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={URLS.DEVICES + '/' + device?.id + '/details'}
    >
      {device?.name}
    </Link>,
    <Typography key="3" color="text.primary">
      Ревизии
    </Typography>,
  ];

  const rootEl = useRef(null);
  useEffect(() => {
    const onClick = (e) => {
      if (rootEl) {
        rootEl?.current?.contains(e.target);
      }
      setSelectRevision(null);
      setSelectVersionRevision(null);
    };

    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  const open = Boolean(contextMenuAnchor);
  const handleClickOpenContextMenu = (event) => {
    setContextMenuAnchor(event.currentTarget);
  };
  const handleCloseContextMenu = () => {
    setContextMenuAnchor(null);
    selectHorizontal(null);
    selectVertical(null);
    selectCell(null);
  };

  const selectVertical = async (revisionId) => {
    setSelectRevision(revisionId);
    setSelectVersion(null);
    setSelectVersionRevision(null);
  };

  const selectHorizontal = async (versionId) => {
    setSelectRevision(null);
    setSelectVersion(versionId);
    setSelectVersionRevision(null);
  };

  const selectCell = async (versionRevisionId) => {
    setSelectVersionRevision(versionRevisionId);
    setSelectRevision(null);
    setSelectVersion(null);
  };
  const cellClassNameActive = classNames([
    styles.SingleDeviceRevisionsTable__Body__Row__Cell,
    styles.SingleDeviceRevisionsTable__Body__Row__Cell__Active,
  ]);
  const cellClassNameDisabled = classNames([
    styles.SingleDeviceRevisionsTable__Body__Row__Cell,
    styles.SingleDeviceRevisionsTable__Body__Row__Cell__Disabled,
  ]);
  const cellClassNameClear = classNames([
    styles.SingleDeviceRevisionsTable__Body__Row__Cell,
    styles.SingleDeviceRevisionsTable__Body__Row__Cell__Clear,
  ]);
  const TITLE_LENGHT_CONST = 10;
  const sliceText = (text, len = TITLE_LENGHT_CONST) => {
    return text?.length > len ? (
      <Tooltip title={text}>
        <div>{text?.slice(0, len) + '...'}</div>
      </Tooltip>
    ) : (
      text
    );
  };
  return (
    (id || isCreate) && (
      <FullPageLayout
        obj={device}
        seoTitle={'Ревизии - ' + device?.name}
        pageTitle={device?.name}
        isCreated={true}
        breadcrumbs={breadcrumbs}
      >
        <Stack direction="row" spacing={2} sx={{ mb: 3, mt: 4 }}>
          <Button
            variant="text"
            color="primary"
            onClick={() =>
              navigate(URLS.DEVICES + '/' + device.id + '/details')
            }
          >
            Основная информация
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              navigate(URLS.DEVICES + '/' + device.id + '/revisions')
            }
            disabled={!device?.id}
          >
            Настройка ревизий и версий
          </Button>
        </Stack>

        {!!revisions?.rows?.length || !!revisions?.headers?.length ? (
          <>
            <TableContainer
              component={Paper}
              className={styles.SingleDeviceRevisionsTable}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead
                  className={styles.SingleDeviceRevisionsTable__Header}
                >
                  <TableRow
                    className={styles.SingleDeviceRevisionsTable__Header__Row}
                  >
                    <TableCell
                      className={
                        styles.SingleDeviceRevisionsTable__Header__Row__Cell
                      }
                    >
                      Версия ПО / Ревизия
                    </TableCell>
                    {revisions?.headers?.map((header, index) => (
                      <TableCell
                        ref={rootEl}
                        key={index}
                        className={
                          styles.SingleDeviceRevisionsTable__Header__Row__Cell
                        }
                        sx={
                          selectRevision == header.revisionId && {
                            border:
                              'solid 1px rgba(25, 118, 210, 1) !important',
                            background: 'rgba(25, 118, 210, 1)',
                            color: '#fff',
                          }
                        }
                        onContextMenu={(e) => {
                          e.preventDefault();
                          selectVertical(header.revisionId);
                          handleClickOpenContextMenu(e);
                        }}
                      >
                        {sliceText(header.revisionName)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className={styles.SingleDeviceRevisionsTable__Body}>
                  {revisions?.rows?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      className={styles.SingleDeviceRevisionsTable__Body__Row}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={
                          styles.SingleDeviceRevisionsTable__Body__Row__Cell
                        }
                        sx={
                          selectVersion == row.versionId && {
                            border:
                              'solid 1px rgba(25, 118, 210, 1) !important',
                            background: 'rgba(25, 118, 210, 1)',
                            color: '#fff',
                          }
                        }
                        onContextMenu={(e) => {
                          e.preventDefault();
                          selectHorizontal(row.versionId);
                          handleClickOpenContextMenu(e);
                        }}
                      >
                        {sliceText(row.versionName)}
                      </TableCell>

                      {row.cells.map((cell, index) => (
                        <TableCell
                          ref={rootEl}
                          key={index}
                          component="th"
                          scope="row"
                          id="context-menu-button"
                          aria-controls={open ? 'context-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={() => {
                            if (permission?.edit && !cell.versionRevisionId) {
                              navigate(
                                `${URLS.DEVICES}/${id}/revisions/${cell.revisionId}/${cell.versionId}/create`,
                              );
                            } else if (
                              (permission?.edit || permission?.view) &&
                              cell.versionRevisionId
                            ) {
                              navigate(
                                `${URLS.DEVICES}/${id}/revisions/${cell.revisionId}/${cell.versionId}/${cell.versionRevisionId}`,
                              );
                            }
                          }}
                          className={
                            cell.isActive
                              ? cellClassNameActive
                              : cell.versionRevisionId
                              ? cellClassNameDisabled
                              : cellClassNameClear
                          }
                          sx={
                            (selectRevision == cell.revisionId ||
                              selectVersion == cell.versionId ||
                              (cell.versionRevisionId &&
                                selectVersionRevision ==
                                  cell.versionRevisionId)) && {
                              border:
                                'solid 1px rgba(25, 118, 210, 1) !important',
                              background: 'rgba(25, 118, 210, 0.3)',
                            }
                          }
                          onContextMenu={(e) => {
                            if (cell.versionRevisionId) {
                              e.preventDefault();
                              selectCell(cell.versionRevisionId);
                              handleClickOpenContextMenu(e);
                            }
                          }}
                        >
                          {!cell.versionRevisionId && permission?.edit && (
                            <div className="icon">
                              <ControlPointIcon />
                            </div>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {permission?.edit && (
              <Menu
                id="context-menu"
                aria-labelledby="context-menu-button"
                anchorEl={contextMenuAnchor}
                open={open}
                onClose={handleCloseContextMenu}
                sx={{ ml: 3, mt: 3 }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {selectRevision && permission?.edit && (
                  <MenuItem
                    onClick={() => {
                      setOpenAdd({
                        open: true,
                        type: 'revision',
                        order:
                          revisions?.headers?.find(
                            (v) => v.revisionId == selectRevision,
                          ).order + 1,
                      });
                      handleCloseContextMenu();
                    }}
                  >
                    <ControlPointIcon sx={{ mr: 1 }} /> Вставить справа
                  </MenuItem>
                )}
                {selectRevision && (
                  <MenuItem
                    onClick={() => {
                      setOpenAdd({
                        open: true,
                        type: 'revision',
                        order: revisions?.headers?.find(
                          (v) => v.revisionId == selectRevision,
                        ).order,
                      });
                      handleCloseContextMenu();
                    }}
                  >
                    <ControlPointIcon sx={{ mr: 1 }} /> Вставить слева
                  </MenuItem>
                )}
                {selectVersion && (
                  <MenuItem
                    onClick={() => {
                      setOpenAdd({
                        open: true,
                        type: 'version',
                        order: revisions?.rows?.find(
                          (v) => v.versionId == selectVersion,
                        ).order,
                      });
                      handleCloseContextMenu();
                    }}
                  >
                    <ControlPointIcon sx={{ mr: 1 }} /> Вставить выше
                  </MenuItem>
                )}
                {selectVersion && (
                  <MenuItem
                    onClick={() => {
                      setOpenAdd({
                        open: true,
                        type: 'version',
                        order:
                          revisions?.rows?.find(
                            (v) => v.versionId == selectVersion,
                          ).order + 1,
                      });
                      handleCloseContextMenu();
                    }}
                  >
                    <ControlPointIcon sx={{ mr: 1 }} /> Вставить ниже
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    if (selectRevision) {
                      removeRevisionHandler(selectRevision);
                    }
                    if (selectVersion) {
                      removeVersionHandler(selectVersion);
                    }
                    if (selectVersionRevision) {
                      removeVersionRevisionHandler(selectVersionRevision);
                    }
                    handleCloseContextMenu();
                  }}
                >
                  <DeleteOutlineIcon sx={{ mr: 1 }} /> Удалить
                </MenuItem>
              </Menu>
            )}
            <ActionApproveV2
              open={openRevisonApprove?.openModal}
              title={openRevisonApprove?.title}
              actionButtonText={openRevisonApprove?.actionButtonText}
              showCloseButton={openRevisonApprove?.showCloseButton}
              handleSuccess={openRevisonApprove?.handleSuccess}
              handleClose={openRevisonApprove?.handleClose}
            />
            <ActionApproveV2
              open={openVersionApprove?.openModal}
              title={openVersionApprove?.title}
              actionButtonText={openVersionApprove?.actionButtonText}
              showCloseButton={openVersionApprove?.showCloseButton}
              handleSuccess={openVersionApprove?.handleSuccess}
              handleClose={openVersionApprove?.handleClose}
            />
            <ActionApproveV2
              open={openVersionRevisionApprove?.openModal}
              title={openVersionRevisionApprove?.title}
              actionButtonText={openVersionRevisionApprove?.actionButtonText}
              showCloseButton={openVersionRevisionApprove?.showCloseButton}
              handleSuccess={openVersionRevisionApprove.handleSuccess}
              handleClose={openVersionRevisionApprove.handleClose}
            />
          </>
        ) : (
          <>
            {permission?.edit && (
              <Button
                variant="outlined"
                color="success"
                sx={{ mt: 3 }}
                onClick={() => {
                  setOpenAdd({ open: true, type: 'all', order: 0 });
                }}
                disabled={!permission?.edit}
              >
                Добавить версию и ревизию
              </Button>
            )}
          </>
        )}
        <Modal
          open={openAdd.open}
          onClose={() => {
            setOpenAdd({ open: false });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box className={styles.ModalAdd}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ mb: 4 }}
            >
              {openAdd.type == 'version'
                ? 'Добавить версию'
                : openAdd.type == 'revision'
                ? 'Добавить ревизию'
                : 'Добавить версию и ревизию'}
            </Typography>
            <FormValidator
              name="device-add-data"
              validationConfig={config}
              changed={{ version: newVersion, revision: newRevision }}
              setErrorsHandler={(data) => handlerError(data)}
            >
              {(openAdd.type == 'version' || openAdd.type == 'all') && (
                <TextField
                  fullWidth
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  sx={{ mb: 2 }}
                  value={newVersion}
                  label="Название версии"
                  placeholder="0.0.0"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="version"
                  required={config.version.isRequired}
                  error={
                    changedData?.version &&
                    (validateErrors?.version?.text || versionValidationError)
                  }
                  helperText={
                    changedData?.version &&
                    (validateErrors?.version?.text || versionValidationError)
                  }
                  onChange={({ target }) => {
                    setChangedData({ ...changedData, version: true });
                    setNewVersion(target?.value);
                  }}
                />
              )}
              {(openAdd.type == 'revision' || openAdd.type == 'all') && (
                <>
                  <TextField
                    fullWidth
                    type="text"
                    label="Название ревизии"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={newRevision}
                    name="revision"
                    required={config.revision.isRequired}
                    error={
                      changedData?.revision && validateErrors?.revision?.text
                    }
                    helperText={
                      changedData?.revision && validateErrors?.revision?.text
                    }
                    onChange={({ target }) => {
                      setChangedData({ ...changedData, revision: true });
                      setNewRevision(target.value);
                    }}
                    sx={{ mb: 2 }}
                  />
                </>
              )}

              <Stack
                direction="row"
                spacing={2}
                justifyContent="end"
                sx={{ mt: 3 }}
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ opacity: '0.4' }}
                  onClick={() => {
                    setNewRevision(undefined);
                    setNewVersion(undefined);
                    setChangedData({ version: false, revision: false });
                    setOpenAdd({ open: false });
                    setSelectRevision(null);
                    setSelectVersion(null);
                    setSelectVersionRevision(null);
                  }}
                >
                  Отмена
                </Button>

                {openAdd.type == 'revision' && (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      Object.keys(validateErrors)?.length || !newRevision
                    }
                    onClick={async () => {
                      try {
                        await createRevisionHandler({
                          Order: openAdd.order,
                          Name: newRevision,
                        });
                        setOpenAdd({ open: false });
                        setSelectRevision(null);
                        setSelectVersion(null);
                        setNewRevision('');
                        setNewVersion(null);
                        getOneDeviceRevisionMapApi
                          .sendRequest(id)
                          .then((result) => setRevisions(result));
                        dispatch(
                          setAlert({
                            status: 200,
                            text: 'Матрица ревизий и версий обновлена',
                          }),
                        );
                      } catch (error) {}
                    }}
                  >
                    Добавить
                  </Button>
                )}
                {openAdd.type == 'version' && (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      Object.keys(validateErrors)?.length ||
                      !newVersion ||
                      versionValidationError
                    }
                    onClick={async () => {
                      try {
                        await createVersionHandler({
                          Order: openAdd.order,
                          Name: newVersion,
                        });
                        setOpenAdd({ open: false });
                        setSelectRevision(null);
                        setSelectVersion(null);
                        setNewRevision('');
                        setNewVersion(null);
                        getOneDeviceRevisionMapApi
                          .sendRequest(id)
                          .then((result) => setRevisions(result));
                        dispatch(
                          setAlert({
                            status: 200,
                            text: 'Матрица ревизий и версий обновлена',
                          }),
                        );
                      } catch (error) {}
                    }}
                  >
                    Добавить
                  </Button>
                )}
                {openAdd.type == 'all' && (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      Object.keys(validateErrors)?.length ||
                      !newVersion ||
                      versionValidationError
                    }
                    onClick={async () => {
                      try {
                        await createVersionHandler({
                          Order: openAdd.order,
                          Name: newVersion,
                        });
                        await createRevisionHandler({
                          Order: openAdd.order,
                          Name: newRevision,
                        });
                        setOpenAdd({ open: false });
                        setSelectRevision(null);
                        setSelectVersion(null);
                        setNewRevision('');
                        setNewVersion(null);
                        getOneDeviceRevisionMapApi
                          .sendRequest(id)
                          .then((result) => setRevisions(result));
                        dispatch(
                          setAlert({
                            status: 200,
                            text: 'Матрица ревизий и версий обновлена',
                          }),
                        );
                      } catch (error) {}
                    }}
                  >
                    Добавить
                  </Button>
                )}
              </Stack>
            </FormValidator>
          </Box>
        </Modal>
      </FullPageLayout>
    )
  );
};
