import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import { getAllSipServers } from '../../api/SipServerApi';
import styles from './SipServerTable.module.scss';

export const SipServerTable = ({ permission }) => {
  const sipServerApi = useApi(getAllSipServers);

  const [limit, setLimit] = useState(15);
  const [sipServers, setSipServers] = useState([]);
  const [sipServersCount, setSipServersCount] = useState(0);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const getSipServers = async (offset = 0) => {
    const [result, count] = await sipServerApi.sendRequest(limit, offset);
    setSipServers(result);
    setSipServersCount(Math.ceil(count / limit));
  };

  useEffect(() => {
    getSipServers(page);
  }, []);

  const handleChange = (event, value) => {
    setPage(value - 1);
    getSipServers(value - 1);
  };

  return (
    <>
      <Table
        sx={{
          minWidth: 650,
          border: 'solid 1px #eee',
          borderRadius: '5px',
          mt: 3,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell alignt="left">
              <b>Название</b>
            </TableCell>
            <TableCell align="left">
              <b>URL-адрес сервера</b>
            </TableCell>
            <TableCell align="center">
              <b>Основной по умолчанию</b>
            </TableCell>
            <TableCell align="center">
              <b>Резервный по умолчанию</b>
            </TableCell>
            <TableCell align="left">
              <b>Дата создания</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sipServers.map((server) => (
            <TableRow
              className={styles?.SipServersList__Row}
              onClick={() =>
                navigate(URLS.SIP_SERVERS + `/${server.id}`)
              }
              key={server.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {server?.name}
              </TableCell>
              <TableCell align="left">{server?.url}</TableCell>
              <TableCell align="center">{server?.isDefaultMain && <DoneIcon/>}</TableCell>
              <TableCell align="center">{server?.isDefaultReserve && <DoneIcon/>}</TableCell>
              <TableCell align="left">
                {format(new Date(server.created), 'dd.MM.yyyy HH:mm:ss')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {sipServersCount > 0 && (
        <Pagination
          count={sipServersCount}
          page={page}
          onChange={handleChange}
          sx={{ mt: 3, mb: 4, ml: 'auto', display: 'block' }}
        />
      )}
    </>
  );
};
