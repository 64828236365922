import {
  FormControlLabel,
  TextField,
  Grid,
  Switch,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  checkApiUrl,
  checkDomain,
  checkUrl,
  deleteSipServer,
  saveNewSipServer,
  updateSipServer,
} from '../../api/SipServerApi';
import { setAlert } from '../../store/alert.store';
import { SipServerFormActionButtons } from '../SipServerFormActionButtons/SipServerFormActionButtons';
import { FormValidator } from '../FormValidator/FormValidator';
import { SipServerValidationConfig } from '../../utils/formValidatorConfigs/config';
import { useApi } from '../../hooks/useApi';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SipServerForm = ({
  isCreate,
  prevPage,
  sipServer,
  permission,
  ...props
}) => {
  const [sipServerState, setSipServerState] = useState({});
  const [validateErrors, setValidateErrors] = useState([]);
  const [customApiUrlError, setCustomApiUrlError] = useState('');
  const [customDomainError, setCustomDomainError] = useState('');
  const [customUrlError, setCustomUrlError] = useState('');
  const [wasChange, setChange] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const saveNewSipServerApi = useApi(saveNewSipServer);
  const upadteSipServerApi = useApi(updateSipServer);
  const deleteSipServerApi = useApi(deleteSipServer);
  const [openApprove, setOpenApprove] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const config = useMemo(() => SipServerValidationConfig(), []);

  useEffect(() => {
    const configKeys = Object.keys(config);

    if (wasChange && Object.keys(validateErrors).length === 0) {
      let canSave = true;
      configKeys.forEach((key) => {
        if (canSave && config[key]?.isRequired && !(key in sipServerState)) {
          canSave = false;
        }
      });
      setFormDisabled(!canSave || customApiUrlError || customDomainError || customUrlError);
    } else if (!formDisabled) {
      setFormDisabled(true);
    }
  }, [config, customApiUrlError, customDomainError, customUrlError, formDisabled, sipServerState, validateErrors, wasChange]);

  const handleSaveNewSipServer = async () => {
    try {
      await saveNewSipServerApi.sendRequest(sipServerState);
      navigate(prevPage);
      dispatch(
        setAlert({
          type: 'success',
          text: 'SIP-сервер добавлен',
          status: 201,
        }),
      );
    } catch (error) {}
  };

  const handleUpdateSipServer = async () => {
    try {
      await upadteSipServerApi.sendRequest(sipServerState);
      navigate(prevPage);
      dispatch(
        setAlert({
          type: 'success',
          text: 'SIP-сервер обновлен',
          status: 200,
        }),
      );
    } catch (error) {}
  };
  const handleDeleteSipServer = async () => {
    await deleteSipServerApi.sendRequest({
      id: sipServerState.id,
    });
    navigate(prevPage);
    dispatch(setAlert({ status: 201, text: 'SIP-сервер удален' }));
  };

  useEffect(() => {
    setSipServerState(sipServer);
  }, [sipServer]);

  const handleError = (data) => {
    setValidateErrors(data);
  };

  const switchOffOpposite = (fieldName) => {
    const el = document.querySelector(fieldName === 'isDefaultReserve' ? '#is-default-main' : '#is-default-reserve');
    if (!el.checked) return;
    el.dispatchEvent(
      new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
      })
    );
  };

  const onChange = (value, fieldName) => {
    if (!wasChange) {
      setChange(true);
    }
    let data = { ...sipServerState, [fieldName]: value };
    if (value) {
      if (fieldName === 'isDefaultReserve') {
        data.isDefaultMain = false;
        switchOffOpposite(fieldName);
      } else if (fieldName === 'isDefaultMain') {
        data.isDefaultReserve = false;
        switchOffOpposite(fieldName);
      }
    }
    setSipServerState(data);
  };

  const handleChancel = () => {
    navigate(prevPage);
  };

  return (
    <div {...props}>
      <FormValidator
        name="sip-server-form-name"
        validationConfig={config}
        changed={sipServerState}
        setErrorsHandler={(data) => handleError(data)}
      >
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Название"
              disabled={!permission?.edit}
              placeholder="Название"
              variant="outlined"
              sx={{ mt: 2 }}
              name="name"
              required={config.name.isRequired}
              error={validateErrors?.name?.text}
              helperText={validateErrors?.name?.text}
              value={sipServerState?.name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'name')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Описание"
              disabled={!permission?.edit}
              placeholder="Описание"
              variant="outlined"
              sx={{ mt: 2 }}
              name="description"
              required={config.description.isRequired}
              error={validateErrors?.description?.text}
              helperText={validateErrors?.description?.text}
              value={sipServerState?.description}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'description')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Домен"
              placeholder="Домен"
              disabled={!permission?.edit}
              variant="outlined"
              sx={{ mt: 2 }}
              name="domain"
              required={config.domain.isRequired}
              error={validateErrors?.domain?.text || customDomainError}
              helperText={validateErrors?.domain?.text || customDomainError}
              value={sipServerState?.domain}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={async ({ target }) => {
                onChange(target.value, 'domain');
                try {
                  await checkDomain(target.value);
                  setCustomDomainError('');
                } catch (error) {
                  setCustomDomainError(error.response.data.message);
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="URL-адрес API"
              placeholder="URL-адрес API"
              disabled={!permission?.edit}
              variant="outlined"
              sx={{ mt: 2 }}
              name="apiUrl"
              required={config.apiUrl.isRequired}
              error={validateErrors?.apiUrl?.text || customApiUrlError}
              helperText={validateErrors?.apiUrl?.text || customApiUrlError}
              value={sipServerState?.apiUrl}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={async ({ target }) => {
                onChange(target.value, 'apiUrl');
                try {
                  await checkApiUrl(target.value);
                  setCustomApiUrlError('');
                } catch (error) {
                  setCustomApiUrlError(error.response.data.message);
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Логин"
              placeholder="Логин"
              disabled={!permission?.edit}
              variant="outlined"
              sx={{ mt: 2 }}
              name="login"
              required={config.login.isRequired}
              error={validateErrors?.login?.text}
              helperText={validateErrors?.login?.text}
              value={sipServerState?.login}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'login')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Пароль"
              placeholder="Пароль"
              disabled={!permission?.edit}
              variant="outlined"
              sx={{ mt: 2 }}
              name="password"
              required={config.password.isRequired}
              error={validateErrors?.password?.text}
              helperText={validateErrors?.password?.text}
              value={sipServerState?.password}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'password')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="URL-адрес"
              placeholder="URL-адрес"
              disabled={!permission?.edit}
              variant="outlined"
              sx={{ mt: 2 }}
              name="url"
              required={config.url.isRequired}
              error={validateErrors?.url?.text || customUrlError}
              helperText={validateErrors?.url?.text || customUrlError}
              value={sipServerState?.url}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={async ({ target }) => {
                onChange(target.value, 'url');
                try {
                  await checkUrl(target.value);
                  setCustomUrlError('');
                } catch (error) {
                  setCustomUrlError(error.response.data.message);
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              sx={{ mt: 3 }}
              control={
                <Switch
                  name="isDefaultMain"
                  id="is-default-main"
                  checked={sipServerState?.isDefaultMain}
                  disabled={!permission?.edit}
                  onChange={({ target }) => {
                    onChange(target.checked, 'isDefaultMain');
                  }}
                />
              }
              label="Основной по умолчанию"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              sx={{ mt: 3 }}
              control={
                <Switch
                  name="isDefaultReserve"
                  id="is-default-reserve"
                  checked={sipServerState?.isDefaultReserve}
                  disabled={!permission?.edit}
                  onChange={({ target }) => {
                    onChange(target.checked, 'isDefaultReserve');
                  }}
                />
              }
              label="Резервный по умолчанию"
            />
          </Grid>
        </Grid>
        <SipServerFormActionButtons
          sipServer={sipServer}
          permission={permission}
          formDisabled={formDisabled}
          handleSaveNewSipServer={() => handleSaveNewSipServer()}
          handleUpdateSipServer={() => handleUpdateSipServer()}
          handleRemoveSipServer={() => setOpenApprove(true)}
          handleChancel={() => handleChancel()}
          loading={
            saveNewSipServerApi?.loading ||
            upadteSipServerApi?.loading ||
            deleteSipServerApi?.loading
          }
        />

        <ActionApproveV2
          target={sipServer}
          open={openApprove}
          title={`Вы действительно хотите удалить данный SIP-сервер?`}
          handleClose={() => setOpenApprove(false)}
          handleSuccess={() => {
            handleDeleteSipServer();
            setOpenApprove(false);
          }}
          showDescription={false}
        />
      </FormValidator>
    </div>
  );
};
